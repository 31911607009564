<template>
  <el-dialog
    v-dragDialog
    title="配置改善方案"
    :visible="visible"
    width="1000px"
    :close-on-click-modal="false"
    append-to-body
    @close="close"
  >
    <el-form
      :model="dataForm"
      ref="dataForm"
      label-width="250px"
      :inline="true"
    >
      <el-form-item label="下肢功能改善" v-if="(checkInfo.CheckTypes & 8) == 8">
        <el-switch v-model="dataForm.LowerLimbs"></el-switch>
      </el-form-item>
      <el-form-item
        label="心肺功能改善"
        v-if="(checkInfo.CheckTypes & 64) == 64"
      >
        <el-switch v-model="dataForm.HeartLung"></el-switch>
      </el-form-item>
      <el-form-item
        label="呼吸功能改善"
        v-if="(checkInfo.CheckTypes & 32) == 32"
      >
        <el-switch v-model="dataForm.Breath"></el-switch>
      </el-form-item>
      <el-form-item label="膳食改善方案" v-if="(checkInfo.CheckTypes & 2) == 2">
        <el-switch v-model="dataForm.RationalDiet"></el-switch>
      </el-form-item>
      <el-form-item label="热量缺口：" v-if="dataForm.RationalDiet">
        <el-input-number
          size="small"
          v-if="dataForm.RationalDiet"
          v-model="dataForm.ReLianQueKou"
        ></el-input-number>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleSetImprove">确定</el-button>
      <el-button type="info" @click="visible = false">取消</el-button>
    </div>
  </el-dialog>
</template>
    <script>
import { setCheckImproves } from "@/api/check/check.js";
import { get } from "@/api/setting/config.js";
export default {
  components: {},
  data() {
    return {
      visible: false,
      checkInfo: "",
      dataForm: {
        LowerLimbs: false,
        HeartLung: false,
        Breath: false,
        RationalDiet: false,
      },
    };
  },
  methods: {
    show(row) {
      this.checkInfo = row;
      this.dataForm = {
        LowerLimbs: (this.checkInfo.ImproveTypes & 8) == 8,
        HeartLung: (this.checkInfo.ImproveTypes & 64) == 64,
        Breath: (this.checkInfo.ImproveTypes & 32) == 32,
        RationalDiet: (this.checkInfo.ImproveTypes & 2) == 2,
        ReLianQueKou: 0,
      };

      if(this.checkInfo.ReLianQueKou==0){
        get({}).then((res) => {
        if (res) {
          if (this.checkInfo.Member.Sex == "女")
            this.dataForm.ReLianQueKou = res.ReLianNv;
          else this.dataForm.ReLianQueKou = res.ReLianNan;
        }
      });
      }
      else{
        this.dataForm.ReLianQueKou =this.checkInfo.ReLianQueKou
      }
      this.visible = true;
    },
    handleSetImprove() {
      let improveValue = 0;
      if (this.dataForm.RationalDiet) {
        improveValue = improveValue | 2;
      }
      if (this.dataForm.LowerLimbs) {
        improveValue = improveValue | 8;
      }
      if (this.dataForm.Breath) {
        improveValue = improveValue | 32;
      }
      if (this.dataForm.HeartLung) {
        improveValue = improveValue | 64;
      }
      let parma = {
        checkKey: this.checkInfo.Key,
        improveTypes: improveValue,
        relian: this.dataForm.ReLianQueKou,
      };
      console.log(parma);
      setCheckImproves(parma).then((res) => {
        if (res) {
          this.msg.successMsg("设置成功！");
          this.$emit("update");
          this.visible = false;
        }
      });
    },
    close() {
      this.visible = false;
    },
  },
  mounted() {},
};
</script>
    <style lang="scss" scoped>
/deep/.el-dialog__body {
  padding: 10px 20px 20px 20px;
}
</style>