var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [{ name: "dragDialog", rawName: "v-dragDialog" }],
      attrs: {
        title: "配置改善方案",
        visible: _vm.visible,
        width: "1000px",
        "close-on-click-modal": false,
        "append-to-body": ""
      },
      on: { close: _vm.close }
    },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: { model: _vm.dataForm, "label-width": "250px", inline: true }
        },
        [
          (_vm.checkInfo.CheckTypes & 8) == 8
            ? _c(
                "el-form-item",
                { attrs: { label: "下肢功能改善" } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.dataForm.LowerLimbs,
                      callback: function($$v) {
                        _vm.$set(_vm.dataForm, "LowerLimbs", $$v)
                      },
                      expression: "dataForm.LowerLimbs"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          (_vm.checkInfo.CheckTypes & 64) == 64
            ? _c(
                "el-form-item",
                { attrs: { label: "心肺功能改善" } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.dataForm.HeartLung,
                      callback: function($$v) {
                        _vm.$set(_vm.dataForm, "HeartLung", $$v)
                      },
                      expression: "dataForm.HeartLung"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          (_vm.checkInfo.CheckTypes & 32) == 32
            ? _c(
                "el-form-item",
                { attrs: { label: "呼吸功能改善" } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.dataForm.Breath,
                      callback: function($$v) {
                        _vm.$set(_vm.dataForm, "Breath", $$v)
                      },
                      expression: "dataForm.Breath"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          (_vm.checkInfo.CheckTypes & 2) == 2
            ? _c(
                "el-form-item",
                { attrs: { label: "膳食改善方案" } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.dataForm.RationalDiet,
                      callback: function($$v) {
                        _vm.$set(_vm.dataForm, "RationalDiet", $$v)
                      },
                      expression: "dataForm.RationalDiet"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.dataForm.RationalDiet
            ? _c(
                "el-form-item",
                { attrs: { label: "热量缺口：" } },
                [
                  _vm.dataForm.RationalDiet
                    ? _c("el-input-number", {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.dataForm.ReLianQueKou,
                          callback: function($$v) {
                            _vm.$set(_vm.dataForm, "ReLianQueKou", $$v)
                          },
                          expression: "dataForm.ReLianQueKou"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSetImprove } },
            [_vm._v("确定")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "info" },
              on: {
                click: function($event) {
                  _vm.visible = false
                }
              }
            },
            [_vm._v("取消")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }